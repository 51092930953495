import React from "react";
import SectionHeading from "../../sectionHeading/sectionHeading.component";
import Skills from "./skills.component";

const SkillsList = ({ techSkills }) => {
  return (
    <div className="cardListLayout paddingTenVertical">
      <SectionHeading sectionId="skills" sectionName="Skills" />
      <div>
        <Skills techSkills={techSkills} />
      </div>
    </div>
  );
};

export default SkillsList;
