import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const FormMessage = (props) => (
  <Grid item xs={12} className="paddingTwentyHorizontal flex justifyCenter">
    <FormControl>
      <TextField
        multiline
        className={props.messageClass}
        htmlFor={props.messageHtmlFor}
        id={props.messageId}
        label={props.messageLabel}
        rows={props.messageRows}
        colSpan={props.messageColSpan}
        width={props.messageWidth}
        value={props.messageValue}
        onChange={props.messageOnChange}
      />
    </FormControl>
  </Grid>
);

export default FormMessage;
