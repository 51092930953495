import React from 'react';
import NavigationBar from '../../components/homepage/navigationBar/homepageNavigationBar.component';
import Banner from '../../components/banner/banner.component';
import About from '../../components/homepage/about/about.component.jsx';
import ProfessionalResume from '../../components/homepage/resumeEducation/professionalResume.component';
import EducationList from '../../components/homepage/resumeEducation/educationList.component';
import Portfolio from '../../components/homepage/portfolio/portfolio.component';
import Contact from '../../components/homepage/contact/contact.component';
import Grid from '@material-ui/core/Grid';
import educationData from '../../components/homepage/resumeEducation/educationData.json';
import skillsData from '../../components/homepage/skills/skillsData.js';

import '../../App.css';
import SkillsList from '../../components/homepage/skills/skillsList.component';

class HomePage extends React.Component {
	constructor() {
		super();
		this.state = {
			educationData: [],
			techSkills: skillsData,
		};
	}

	render() {
		return (
			<div>
				<NavigationBar />
				<Banner banner="mainBanner"/>
				<Grid item xs={12}>
					<About />
				</Grid>
				<Grid container justify="center" spacing={2} direction="row">
					<Grid item xs={11} sm={5}>
						<ProfessionalResume />
					</Grid>
					<Grid item xs={11} sm={5}>
						<EducationList educationData={educationData} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Portfolio />
				</Grid>
				<Grid item xs={12}>
					<SkillsList techSkills={this.state.techSkills} />
				</Grid>
				<Grid item xs={12}>
					<Contact />
				</Grid>
			</div>
		);
	}
}

export default HomePage;
