import React from "react";

const SocialLink = (props) => (
  <li>
    <a
      href={props.socialUrl}
      className={props.aStyling}
      target={props.socialTarget}
      rel={props.socialRel}
      download={props.socailDownload}
      alt={props.socialAlt}
    >
      {props.socialBody}
    </a>
  </li>
);

export default SocialLink;
