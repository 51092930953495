const skillsData = [
	{
        "id": 1,
        "name": "Front End",
        "skills": ["HTML ",
                        " CSS ",
                        " JavaScript ",
                        " React ",
                        " jQuery"
        ],
	},
	{
        "id": 2,
        "name": "Back End",      
        "skills": [
                "JavaScript ",
                " Python",
                "  Node ",
                " Express"]      
        },
        {
        "id": 3,
        "name": "DataBase",       
        "skills": [
                "PostgreSQL ",
                 " SQL"]
        },
        {
        "id": 4,
        "name": "Hosting",
        "skills": [
                "Digital Ocean ",
                " Heroku"]                   
                },
        {
        "id": 5,
        "name": "Web Server",          
        "skills": [
                "Apache2 ",
                " Nginx"]              
        },
        {
        "id": 6,
        "name": "Server Management",           
        "skills": [
                " Git Bash ",
                 " Terminal"]
        },
        {
        "id": 7,
        "name": "Ui Framework",           
        "skills": [
                "Boostrap ",
                "Material-UI ",
                ]
        },
        {
        "id": 8,
        "name": "Soft Skills",           
        "skills": [
                "Effective Listening ",
                "Scrum: Basics",
                ]
        },
]

export default skillsData;