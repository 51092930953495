import React from "react";
import Grid from "@material-ui/core/Grid";
import { ImHome } from "react-icons/im";

import "../../../App.css";
import tmplogo from "../../Images/tmBlue44Orange.png";

class NavigationBar extends React.Component {
  render() {
    return (
      <Grid
        container
        className="bgGray site-nav paddingTen"
        direction="row"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={2}>
          <nav href="/">
            <a href="/" className="customCursor">
              <img className="navLogo paddingTen" src={tmplogo} alt="" />
            </a>
          </nav>
        </Grid>
        <Grid item xs={10}>
          <nav className="navLinkStyling">
            <a
              href="/"
              alt="Scroll Top Of The Page"
              className="paddingTen  customCursor"
            >
              <ImHome className="colorOrange" fontSize="xlarge" />
            </a>
            <a
              href="#about"
              alt="Scroll To About Section"
              className="paddingTen scrollto customCursor textDecorationNone"
            >
              About
            </a>
            <a
              href="#resume"
              alt="Scroll To Resume Section"
              className="paddingTen scrollto customCursor textDecorationNone"
            >
              Resume
            </a>
            <a
              href="#portfolio"
              alt="Scroll To Portfolio Section"
              className="paddingTen scrollto customCursor textDecorationNone"
            >
              Portfolio
            </a>
            <a
              href="#skills"
              alt="Scroll To Skills Section"
              className="paddingTen scrollto customCursor textDecorationNone"
            >
              Skills
            </a>
            <a
              href="#contact"
              alt="Scroll To Contact Form"
              className="paddingTen scrollto customCursor textDecorationNone"
            >
              Contact
            </a>
          </nav>
        </Grid>
      </Grid>
    );
  }
}

export default NavigationBar;
