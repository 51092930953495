import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { MdExpandMore } from "react-icons/md";

const ResumeAccordian = (props) => (
  <Accordion className="marginVerticalFive">
    <AccordionSummary
      className="bgGray"
      expandIcon={<MdExpandMore />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography className="colorOrange  customCursor">
        {props.companyName}
      </Typography>
    </AccordionSummary>
    <AccordionDetails className="justifyCenter ">
      <ul className="listStyling listStyleNone textCenter accordianFont">
        <li className="paddingTenVertical">{props.item1}</li>
        <li className="paddingTenVertical">{props.item2}</li>
        <li className="paddingTenVertical">{props.item3}</li>
        <li className="paddingTenVertical">{props.item4}</li>
      </ul>
    </AccordionDetails>
  </Accordion>
);

export default ResumeAccordian;
