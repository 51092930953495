import React from "react";
import SectionHeading from "../../sectionHeading/sectionHeading.component";
import Education from "./education.component";

const EducationList = ({ educationData }) => {
  return (
    <div className="sectionPBottom">
      <SectionHeading sectionName="Education" />
      {educationData.map((education, i) => {
        return (
          <div>
            <Education
              key={i}
              id={education.id}
              school={education.school}
              program={education.program}
              accreditationType={education.accreditationType}
              hyperlink={education.hyperlink}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EducationList;
