import React from "react";
import ResumeAccordian from "./professionalResumeAccordian.component";
import SectionHeading from "../../sectionHeading/sectionHeading.component";

class ProfessionalResume extends React.Component {
  render() {
    return (
      <div>
        <SectionHeading sectionId="resume" sectionName="Resume" />
        <div className="textCenter">
          <ResumeAccordian
            companyName="Black Wall Design Co"
            item1="Technology: (HTML/CSS/REACT/Node/Express/Firebase/ React-Bootstrap)"
            item2="Conducting UAT by gathering feedback from the client"
            item3="Utilizing React to help resolve slow load times for a better user experience"
            item4="Integrating Firebase, OAuth 2.0 Google authentication, with Cloud Firestore"
          />
          <ResumeAccordian
            companyName="Hive Labs Corportion"
            item1="Technology: (React/Material-Ui/HTML/CSS/Axios)"
            item2="Brought Adobe XD React single page design vision to life with reusable components"
            item3="Chose Material-UI to fulfill UX/UI needs &amp; decrease build time to meet deadline"
            item4="Evaluated sign up form functionality with Postman and Nodemon before integration"
          />
          <ResumeAccordian
            companyName="McCabe Ent Consulting"
            item1="Technology: (HTML5/CSS3/JavaScript/jQuery/Boostrap4/)"
            item2="Utilized jQuery scripts to modernize website with animations"
            item3="Securely deployed site using DigitalOcean, MobaXterm"
            item4="Utilized CloudFlare for cost effective  DNS management &amp; SSL/TLS encryption"
          />
          <ResumeAccordian
            companyName="Personal Development Projects"
            item1="Current Portfolio Website: (PERN Stack)"
            item2="First Portfolio website: (HTML5/CSS3/JS/Jquery)"
            item3="YEA:(React/Redux/Firebase/SCSS/React-Router)"
            item4="React Native App"
          />
        </div>
      </div>
    );
  }
}

export default ProfessionalResume;
