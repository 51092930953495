import React from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MdSend } from "react-icons/md";
import Button from "@material-ui/core/Button";
import FormInput from "../../formComponents/formInput.component";
import FormMessage from "../../formComponents/formMessage.component";
import SectionHeading from "../../sectionHeading/sectionHeading.component";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      service: "",
      message: "",
    };
  }

  handleSubmit(e) {
    const API_ROOT_URL = window.location.origin || "http://localhost:3005";
    e.preventDefault();
    axios({
      method: "POST",
      // Needs to change during deployment
      url: `${API_ROOT_URL}/send`,
      data: this.state,
    }).then((response) => {
      if (response.data.status === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        alert("Message failed to send.");
      }
    });
  }

  resetForm() {
    this.setState({ name: "", email: "", service: "", message: "" });
  }

  render() {
    const serviceList = [
      "Full Time Role",
      "Contract Role",
      "Web Development",
      "Wix Development",
      "Other",
    ];

    return (
      <Grid container direction="row" spacing={1} className="bgGrey">
        <Grid xs={12} item>
          <SectionHeading sectionId="contact" sectionName="Contact" />
        </Grid>
        <Grid item className="textCenter" xs={12}>
          <form
            className=""
            id="contact-form"
            onSubmit={this.handleSubmit.bind(this)}
            method="POST"
          >
            <FormInput
              sizeXS={5}
              sizeSm={4}
              inputHtmlFor="name"
              inputName="Name"
              inputType="text"
              inputId="name"
              inputValue={this.state.name}
              inputOnChange={this.onNameChange.bind(this)}
              inputVariant="outlined"
            />
            <FormInput
              sizeXS={5}
              sizeSm={4}
              inputHtmlFor="email"
              inputName="Email"
              inputType="email"
              inputId="email"
              inputvalue={this.state.email}
              inputOnChange={this.onEmailChange.bind(this)}
              inputVariant="outlined"
            />
            <Grid item xs={11} sm={12} className="paddingTwentyHorizontal">
              <FormControl>
                <InputLabel htmlFor="service">Service</InputLabel>
                <Select
                  className="serviceWidth serviceFont"
                  id="service"
                  value={this.state.service}
                  onChange={this.onServiceChange.bind(this)}
                >
                  {serviceList.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <FormMessage
              messageClass="MuiTextField-root"
              messageHtmlFor="message"
              messageId="message"
              messageLabel="Message"
              messageRows={4}
              messageColSpan={8}
              messageWidth={400}
              messageValue={this.state.message}
              messageOnChange={this.onMessageChange.bind(this)}
            />
            <Grid
              item
              xs={12}
              className="justifyCenter alignCenter paddingTwentyHorizontal"
            >
              <Button
                type="submit"
                className="fontWhite bgOrange marginVerticalTwenty"
                variant="contained"
                color="secondary"
                size="small"
                endIcon={<MdSend fontSize="medium" />}
              >
                Send
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onServiceChange(event) {
    this.setState({ service: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
}

export default Contact;
