import React from "react";
import { Switch, Route } from "react-router-dom";

import "./App.css";
import HomePage from "./pages/homePage/homePage.component";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      // currentUser: null,
    };
  }
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>
      </div>
    );
  }
}

export default App;
