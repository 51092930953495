import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import { FaExternalLinkAlt } from "react-icons/fa";
import FAM from "../../Images/FinancialActionManager.png";
import BWDC from "../../Images/blackWallDesign.png";
import YEA from "../../Images/YEA.png";
import MENT from "../../Images/mccabeEntConsulting.png";
import PP from "../../Images/previousportfolio.png";
import SectionHeading from "../../sectionHeading/sectionHeading.component";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: "#f9f9f9",
    paddingBottom: "5%",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
  },
  title: {
    color: "#ffffff",
  },
  titleBar: {
    background:
      "linear-gradient(0deg, hsla(0, 0%, 96%, .7) 0%, hsla(0, 0%, 96%, .8) 0%, hsla(0, 0%, 98%, .9) 100%)",
  },
}));

const tileData = [
  {
    img: FAM,
    imgTitle: "Financial Action Manager (FAM)",
    url: "https://www.fampremium.com/",
    alt: "Link to FAM",
  },
  {
    img: BWDC,
    imgTitle: "Black Wall Design Co (In Development)",
    url: "https://www.blackwalldesign.co/",
    alt: "Link to Black Wall Design Co",
  },
  {
    img: MENT,
    imgTitle: "McCabe Ent Consulting",
    url: "https://mccabeentconsulting.com/",
    alt: "Link to McCabe Ent Consulting",
  },
  {
    img: YEA,
    imgTitle: "Your Everyday Accessories (In Development)",
    url: "https://thomasmccabe.com",
    alt: "No URL Present",
  },
  {
    img: PP,
    imgTitle: "My Previous Portfolio",
    url: "https://previousportfolio.herokuapp.com/",
    alt: "My Pervious Portfolio",
  },
];

const Portfolio = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SectionHeading sectionId="portfolio" sectionName="Portfolio" />
      <GridList className={classes.gridList} cellHeight={"auto"} col={12}>
        {tileData.map((tile) => (
          <GridListTile key={tile.img}>
            <img className="portfolioTileImg" src={tile.img} alt={tile.title} />
            <GridListTileBar
              title={tile.imgTitle}
              classes={{ root: classes.titleBar, title: classes.title }}
              actionIcon={
                <a
                  href={tile.url}
                  alt={tile.alt}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton aria-label="External Link">
                    <FaExternalLinkAlt className="hyperlinkColor customCursor" />
                  </IconButton>
                </a>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export default Portfolio;
