import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

const FormInput = (props) => (
  <Grid
    item
    xs={props.sizeXm}
    sm={props.sizeSm}
    className="inlineFlex paddingTwentyHorizontal"
  >
    <FormControl>
      <InputLabel htmlFor={props.inputHtmlFor}>{props.inputName}</InputLabel>
      <Input
        type={props.inputType}
        className=""
        id={props.inputId}
        value={props.inputValue}
        onChange={props.inputOnChange}
        variant={props.inputVariant}
      />
    </FormControl>
  </Grid>
);

export default FormInput;
