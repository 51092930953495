import React from "react";
import tmp from "../../Images/tmp.jpg";
import Resume from "./assets/mccabeThomasResume.pdf";
import { SiLinkedin, SiGithub, SiInstagram } from "react-icons/si";
import { HiDocumentDownload } from "react-icons/hi";
import SocialLink from "./aboutSocials.component";
import SectionHeading from "../../sectionHeading/sectionHeading.component";

const About = () => (
  <div className="bgGrey layoutPadding flexWrap">
    <SectionHeading sectionId="about" sectionName="About Me" />
    <img
      className="profileImg h2Padding marginAuto"
      src={tmp}
      alt="Thomas Profile"
    />

    <div className="widthStylining textCenter marginAuto">
      <p>
        My Key highlights: Front-End Web Developer, lifelong learner, and
        entrepreneur
      </p>
      <p>
        I am a self-taught developer looking for a junior developer role where I
        can continue to learn and grow, to solve more and more complex problems.
        I have spent most of my training as a developer focusing on Front End
        Development, and I have used those skills to create this portfolio and
        many other freelance and personal projects.
      </p>
      <p>
        My current career goal is to become a full-stack developer so I can
        solve bigger problems, contribute more to my prospective team, and
        produce high-quality clean code for any product or application I am
        working on.
      </p>
    </div>
    <ul className="listStyleNone brand marginAuto">
      <SocialLink
        aStyling="colorOrange customCursor"
        socialUrl="https://www.linkedin.com/in/thomasmccabe44/"
        socialTarget="_blank"
        socialRel="noopener noreferrer"
        socialBody={<SiLinkedin size="2em" />}
        socialAlt="Link to LinkedIn"
      />
      <SocialLink
        aStyling="colorOrange customCursor"
        socialUrl="https://github.com/thomasmccabe44"
        socialTarget="_blank"
        socialRel="noopener noreferrer"
        socialBody={<SiGithub size="2em" />}
        socialAlt="Link to GitHub"
      />
      <SocialLink
        aStyling="colorOrange customCursor"
        socialUrl="https://www.instagram.com/thomas.mccabe.44/"
        socialTarget="_blank"
        socialRel="noopener noreferrer"
        socialBody={<SiInstagram size="2em" />}
        socialAlt="Link to Instagram"
      />
      <SocialLink
        aStyling="colorOrange customCursor"
        socialUrl={Resume}
        socialDownload="mccabeThomasResume.pdf"
        socialTarget="_blank"
        socialRel="noopener noreferrer"
        socialBody={<HiDocumentDownload size="2.5em" />}
        socialAlt="Download Resume PDF"
      />
    </ul>
  </div>
);

export default About;
