import React from "react";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { MdExpandMore } from "react-icons/md";

const Education = ({ school, program, accreditationType, hyperlink }) => {
  return (
    <div>
      <Grid className="textCenter ">
        <Accordion className="textCenter marginVerticalFive">
          <AccordionSummary

            className="bgGray"
            expandIcon={<MdExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography justify={'center'} className="colorOrange customCursor">
              {school}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <a
                className="hyperlinkColor customCursor"
                href={hyperlink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {program + " " + accreditationType}
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
};

export default Education;
