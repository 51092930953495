import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiReact,
  SiPython,
  SiNodeDotJs,
  SiPostgresql,
  SiDigitalocean,
  SiHeroku,
  SiApache,
  SiNginx,
  SiGit,
  SiMongodb,
  SiBootstrap,
  SiMaterialUi,
} from "react-icons/si";
import { DiScrum } from "react-icons/di";
import { IoEarOutline } from "react-icons/io5";
import { HiTerminal } from "react-icons/hi";
import { FaPeopleCarry, FaComments } from "react-icons/fa";
import { RiEmpathizeLine, RiLightbulbFlashLine } from "react-icons/ri";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={6}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Skills = ({ techSkills }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const skillSize = "2.5em";

  const skillsTab = techSkills.map((skillName, i) => (
    <Tab
      className="customCursor"
      key={i}
      label={skillName.name}
      {...a11yProps(i)}
    />
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar
        className="textCenter justifyCenter"
        position="static"
        color="default"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="Developer Skills"
        >
          {skillsTab}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiHtml5
            size={skillSize}
            className="iconStyles colorHTML"
            title="HTML"
          />
          <SiCss3
            size={skillSize}
            className="iconStyles colorCSS"
            title="CSS"
          />
          <SiJavascript
            size={skillSize}
            className="iconStyles colorJS"
            title="JavaScript"
          />
          <SiReact
            size={skillSize}
            className="iconStyles colorReact"
            title="React"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiPython
            size={skillSize}
            className="iconStyles colorPython"
            title="Python"
          />
          <SiNodeDotJs
            size={skillSize}
            className="iconStyles colorNode"
            title="NodeJS"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiPostgresql
            size={skillSize}
            className="iconStyles colorPostgre"
            title="PostgreSQL"
          />
          <SiMongodb
            size={skillSize}
            className="iconStyles colorMongo"
            title="MongoDB"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiDigitalocean
            size={skillSize}
            className="iconStyles colorDigitalO"
            title="Digital Ocean"
          />
          <SiHeroku
            size={skillSize}
            className="iconStyles colorHeroku"
            title="Heroku"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiApache
            size={skillSize}
            className="iconStyles colorApache"
            title="Apache"
          />
          <SiNginx
            size={skillSize}
            className="iconStyles colorNginx"
            title="Nginx"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiGit size={skillSize} className="iconStyles colorGit" title="Git" />
          <HiTerminal
            size={skillSize}
            className="iconStyles"
            title="Terminal"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <SiBootstrap
            size={skillSize}
            className="iconStyles colorBootstrap"
            title="Bootstrap4"
          />
          <SiMaterialUi
            size={skillSize}
            className="iconStyles colorMaterialUI"
            title="Material-Ui"
          />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <ul className=" paddingZero marginAuto textCenter listStyleNone">
          <IoEarOutline
            size={skillSize}
            className="iconStyles colorEar"
            title="Effective Listening"
          />
          <DiScrum
            size={skillSize}
            className="iconStyles colorScrum"
            title="Scrum: Basics"
          />
          <FaPeopleCarry
            size={skillSize}
            className="iconStyles colorNode"
            title="Team Work"
          />
          <RiEmpathizeLine
            size={skillSize}
            className="iconStyles colorApache"
            title="Empathy"
          />
          <FaComments
            size={skillSize}
            className="iconStyles colorScrum"
            title="Communication"
          />
          <RiLightbulbFlashLine
            size={skillSize}
            className="iconStyles colorJS"
            title="Creativity"
          />
        </ul>
      </TabPanel>
    </div>
  );
};

export default Skills;
